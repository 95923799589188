import * as React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Seo = ({ description, lang, meta, title, isArticle, metaKeywords }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: [
            "Duckeneers",
            "Corack",
            "Corona",
            "COVID",
            "COVID-19",
            "3G",
            "Arbeitsplatz",
            metaKeywords,
          ].join(", "),
        },
        {
          name: `image`,
          content: `${site.siteMetadata.siteUrl}/images/logo.png`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: isArticle ? `article` : `website`,
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}/images/logo.png`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: `${site.siteMetadata.siteUrl}/images/logo.png`,
        },
        {
          name: `twitter:image:alt`,
          content: `Duckeneers`,
        },
      ].concat(meta)}
    />
  );
};

Seo.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
  isArticle: false,
  metaKeywords: [],
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  isArticle: PropTypes.bool,
  metaKeywords: PropTypes.arrayOf(PropTypes.string),
};

export default Seo;
