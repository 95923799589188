import React from "react";
import "./component.scss";
import Logo from "../assets/images/logo.png";
import { Link } from "gatsby";

const LayoutComponent: React.FC<{ hideGreenBox?: boolean }> = ({
  hideGreenBox,
  children,
}) => {
  return (
    <div className="layout-component--wrapper">
      {hideGreenBox || <div className="layout-component--green-box" />}
      <div className="layout-component--header">
        <Link to="/">
          <img id="logo" src={Logo} />
        </Link>
        <div id="menu-items">
          <Link to="/#function">
            <span>Funktionen</span>
          </Link>
          <Link to="/#price">
            <span>Preis</span>
          </Link>
          <Link to="/#contact">
            <span>Kontakt</span>
          </Link>
        </div>
      </div>
      {children}
      <div className="layout-component--footer">
        <div className="layout-component--footer-part">
          <small>Made by</small>
          <p>Duckeneers GmbH</p>
          <p>Leitzstraße 45</p>
          <p>70469 Stuttgart</p>
        </div>
        <div className="layout-component--footer-part">
          <small>Legal</small>
          <p>
            <Link to="/agb">Vertragsbedingungen</Link>
          </p>
          <p>
            <Link to="/imprint">Impressum</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LayoutComponent;
